// extracted by mini-css-extract-plugin
export var header = "s_bk";
export var btnMenuWrapper = "s_bl";
export var userSection = "s_bm";
export var headerSection = "s_bn";
export var searchInput = "s_bp";
export var navMenu = "s_bh";
export var avatar = "s_5";
export var btnCreate = "s_bq";
export var mobileMenu = "s_br";
export var visible = "s_bs";
export var rotate = "s_c";
export var dotty = "s_d";