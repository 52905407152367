import React from 'react'
import dayjs from 'dayjs'

import {
  AuctionCategory,
  AuctionHistoryItemType,
  AuctionStatus,
  NotificationCategory,
  NotificationType,
  TransactionStatus,
  TransactionType,
} from '~utils/enums'

const MOCK_PROFILE_PAGE_AUCTIONS = [
  {
    id: 1,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Lorem ipsum',
    priceStatus: 'Reserved Price',
    price: 4,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.ON_SALE,
    status: AuctionStatus.LIVE,
    countryId: 1,
    cityId: 10,
    timestampCreated: dayjs().subtract(0, 'd').subtract(7, 'm').unix(),
  },
  {
    id: 2,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Suspendisse gravida purus',
    priceStatus: 'Reserved Price',
    price: 12,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.ON_SALE,
    status: AuctionStatus.COMPLETED,
    countryId: 1,
    cityId: 11,
    timestampCreated: dayjs().subtract(0, 'd').subtract(27, 'm').unix(),
  },
  {
    id: 3,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Dolor sit amet',
    priceStatus: 'Reserved Price',
    price: 4,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.ON_SALE,
    status: AuctionStatus.LIVE,
    countryId: 1,
    cityId: 10,
    timestampCreated: dayjs().subtract(0, 'd').subtract(67, 'm').unix(),
  },
  {
    id: 4,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Id sem condimentum',
    priceStatus: 'Reserved Price',
    price: 4,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.ON_SALE,
    status: AuctionStatus.UPCOMING,
    countryId: 1,
    cityId: 12,
    timestampCreated: dayjs().subtract(1, 'd').subtract(34, 'm').unix(),
  },
  {
    id: 5,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Consectetur adipiscing elit',
    priceStatus: 'Reserved Price',
    price: 4,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.ON_SALE,
    status: AuctionStatus.LIVE,
    countryId: 1,
    cityId: 11,
    timestampCreated: dayjs().subtract(2, 'd').subtract(734, 'm').unix(),
  },
  {
    id: 6,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Etiam quis sodales',
    priceStatus: 'Reserved Price',
    price: 1,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.ON_SALE,
    status: AuctionStatus.LIVE,
    countryId: 1,
    cityId: 10,
    timestampCreated: dayjs().subtract(4, 'd').subtract(7456, 'm').unix(),
  },
  {
    id: 7,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Nec auctor justo',
    priceStatus: 'Reserved Price',
    price: 5,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    category: AuctionCategory.CREATED,
    hasControls: true,
    status: AuctionStatus.LIVE,
    countryId: 1,
    cityId: 9,
    timestampCreated: dayjs().subtract(4, 'd').subtract(2347, 'm').unix(),
  },
  {
    id: 8,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'A lobortis risus efficitur',
    priceStatus: 'Reserved Price',
    price: 12,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    category: AuctionCategory.CREATED,
    hasControls: true,
    status: AuctionStatus.UPCOMING,
    countryId: 1,
    cityId: 9,
    timestampCreated: dayjs().subtract(1, 'd').subtract(567, 'm').unix(),
  },
  {
    id: 9,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Sed pulvinar',
    priceStatus: 'Reserved Price',
    price: 16,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    category: AuctionCategory.CREATED,
    hasControls: true,
    status: AuctionStatus.LIVE,
    countryId: 1,
    cityId: 11,
    timestampCreated: dayjs().subtract(1, 'd').subtract(8763, 'm').unix(),
  },
  {
    id: 10,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Volutpat risus',
    priceStatus: 'Reserved Price',
    price: 11,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.SOLD,
    status: AuctionStatus.LIVE,
    countryId: 1,
    cityId: 10,
    timestampCreated: dayjs().subtract(0, 'd').subtract(5477, 'm').unix(),
  },
  {
    id: 11,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Ut pellentesque',
    priceStatus: 'Reserved Price',
    price: 4,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.SOLD,
    status: AuctionStatus.COMPLETED,
    countryId: 1,
    cityId: 9,
    timestampCreated: dayjs().subtract(4, 'd').subtract(5347, 'm').unix(),
  },
  {
    id: 12,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Empire State Building',
    priceStatus: 'Cras in faucibus massa',
    price: 18,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.SOLD,
    status: AuctionStatus.COMPLETED,
    countryId: 2,
    cityId: 6,
    timestampCreated: dayjs().subtract(6, 'd').subtract(7345, 'm').unix(),
  },
  {
    id: 13,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Empire State Building',
    priceStatus: 'Nullam sed',
    price: 6,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.SOLD,
    status: AuctionStatus.LIVE,
    countryId: 2,
    cityId: 5,
    timestampCreated: dayjs().subtract(8, 'd').subtract(227, 'm').unix(),
  },
  {
    id: 14,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Sed finibus',
    priceStatus: 'Reserved Price',
    price: 2,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.SOLD,
    status: AuctionStatus.COMPLETED,
    countryId: 2,
    cityId: 5,
    timestampCreated: dayjs().subtract(2, 'd').subtract(347, 'm').unix(),
  },
  {
    id: 15,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Massa ac',
    priceStatus: 'Reserved Price',
    price: 2,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.SOLD,
    status: AuctionStatus.COMPLETED,
    countryId: 2,
    cityId: 7,
    timestampCreated: dayjs().subtract(0, 'd').subtract(9997, 'm').unix(),
  },
  {
    id: 16,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Interdum lectus',
    priceStatus: 'Reserved Price',
    price: 1,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.SOLD,
    status: AuctionStatus.UPCOMING,
    countryId: 2,
    cityId: 6,
    timestampCreated: dayjs().subtract(1, 'd').subtract(11111, 'm').unix(),
  },
  {
    id: 17,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Facilisis sodales',
    priceStatus: 'Reserved Price',
    price: 9,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.SOLD,
    status: AuctionStatus.COMPLETED,
    countryId: 2,
    cityId: 5,
    timestampCreated: dayjs().subtract(12, 'd').subtract(7444, 'm').unix(),
  },
  {
    id: 18,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Nulla imperdiet',
    priceStatus: 'Reserved Price',
    price: 11,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.SOLD,
    status: AuctionStatus.LIVE,
    countryId: 2,
    cityId: 8,
    timestampCreated: dayjs().subtract(1, 'd').subtract(733, 'm').unix(),
  },
  {
    id: 19,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Etiam vestibulum',
    priceStatus: 'Reserved Price',
    price: 15,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.BIDDING,
    status: AuctionStatus.UPCOMING,
    countryId: 3,
    cityId: 1,
    timestampCreated: dayjs().subtract(4, 'd').subtract(3437, 'm').unix(),
  },
  {
    id: 20,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Etiam vestibulum',
    priceStatus: 'Nisl ac varius',
    price: 14,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.BIDDING,
    status: AuctionStatus.COMPLETED,
    countryId: 3,
    cityId: 4,
    timestampCreated: dayjs().subtract(3, 'd').subtract(337, 'm').unix(),
  },
  {
    id: 21,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Nisi non elit',
    priceStatus: 'Reserved Price',
    price: 10,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.COLLECTIBLES,
    status: AuctionStatus.UPCOMING,
    countryId: 3,
    cityId: 3,
    timestampCreated: dayjs().subtract(0, 'd').subtract(347, 'm').unix(),
  },
  {
    id: 22,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Quisque id',
    priceStatus: 'Reserved Price',
    price: 6,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.COLLECTIBLES,
    status: AuctionStatus.LIVE,
    countryId: 3,
    cityId: 3,
    timestampCreated: dayjs().subtract(0, 'd').subtract(17, 'm').unix(),
  },
  {
    id: 23,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Dignissim',
    priceStatus: 'Reserved Price',
    price: 8,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.COLLECTIBLES,
    status: AuctionStatus.COMPLETED,
    countryId: 3,
    cityId: 2,
    timestampCreated: dayjs().subtract(0, 'd').subtract(2, 'm').unix(),
  },
  {
    id: 24,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Praesent lacinia',
    priceStatus: 'Reserved Price',
    price: 5,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.COLLECTIBLES,
    status: AuctionStatus.LIVE,
    countryId: 3,
    cityId: 2,
    timestampCreated: dayjs().subtract(1, 'd').subtract(7, 'm').unix(),
  },
  {
    id: 25,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Eget elementum ex',
    priceStatus: 'Reserved Price',
    price: 4,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.MY_PROPERTIES,
    status: AuctionStatus.COMPLETED,
    countryId: 3,
    cityId: 1,
    timestampCreated: dayjs().subtract(15, 'd').subtract(99, 'm').unix(),
  },
  {
    id: 26,
    authorImgSrc: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    authorName: '@johnsmith',
    imgSrc: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
    title: 'Fusce eu purus',
    priceStatus: 'Reserved Price',
    price: 3,
    footerIcon: 'icon-clock',
    timing: 'Ends in 6h 20m 35s',
    hasControls: true,
    category: AuctionCategory.MY_PROPERTIES,
    status: AuctionStatus.LIVE,
    countryId: 3,
    cityId: 1,
    timestampCreated: dayjs().subtract(2, 'd').subtract(8678, 'm').unix(),
  },
]

const MOCK_NOTIFICATIONS = [
  {
    id: 1,
    type: NotificationType.BID_PLACED,
    user: {
      name: '@darrelljones',
      avatarImg: (
        <img
          src={`https://placeimg.com/96/96/people?nocache=${Math.random()}`}
          alt="avatar"
          width="100%"
          height="100%"
        />
      ),
      profileUrl: '/',
      isVerified: true,
    },
    auction: { name: 'Empire State Building', url: '/' },
    timestamp: dayjs().subtract(7, 'm').unix(),
    unread: true,
    category: NotificationCategory.BIDS,
  },
  {
    id: 2,
    type: NotificationType.PUT_ON_SALE,
    user: {
      name: '@elonmusk',
      avatarImg: (
        <img
          src={`https://placeimg.com/96/96/people?nocache=${Math.random()}`}
          alt="avatar"
          width="100%"
          height="100%"
        />
      ),
      profileUrl: '/',
      isVerified: true,
    },
    auction: { name: 'Namsan Tower', url: '/' },
    timestamp: dayjs().subtract(25, 'h').unix(),
    unread: true,
    category: NotificationCategory.FOLLOWING_ACTIONS,
  },
  {
    id: 3,
    type: NotificationType.BID_PLACED,
    user: {
      name: '@katyperry',
      avatarImg: (
        <img
          src={`https://placeimg.com/96/96/people?nocache=${Math.random()}`}
          alt="avatar"
          width="100%"
          height="100%"
        />
      ),
      profileUrl: '/',
      isVerified: false,
    },
    auction: { name: 'Sensō-ji', url: '/' },
    timestamp: dayjs().subtract(2, 'd').unix(),
    unread: false,
    category: NotificationCategory.MY_NFTS,
  },
  {
    id: 4,
    type: NotificationType.NEW_FOLLOWER,
    user: {
      name: '@elonmusk',
      avatarImg: (
        <img
          src={`https://placeimg.com/96/96/people?nocache=${Math.random()}`}
          alt="avatar"
          width="100%"
          height="100%"
        />
      ),
      profileUrl: '/',
      isVerified: false,
    },
    timestamp: dayjs().subtract(32, 'd').subtract(3, 'y').unix(),
    unread: false,
    category: NotificationCategory.NEW_FOLLOWERS,
  },
  {
    id: 6,
    type: NotificationType.NEW_FOLLOWER,
    user: {
      name: '@gaben',
      avatarImg: (
        <img
          src={`https://placeimg.com/96/96/people?nocache=${Math.random()}`}
          alt="avatar"
          width="100%"
          height="100%"
        />
      ),
      profileUrl: '/',
      isVerified: false,
    },
    timestamp: dayjs().subtract(38, 'd').subtract(3, 'y').unix(),
    unread: false,
    category: NotificationCategory.NEW_FOLLOWERS,
  },
  {
    id: 7,
    type: NotificationType.NEW_FOLLOWER,
    user: {
      name: '@jeff',
      avatarImg: (
        <img
          src={`https://placeimg.com/96/96/people?nocache=${Math.random()}`}
          alt="avatar"
          width="100%"
          height="100%"
        />
      ),
      profileUrl: '/',
      isVerified: false,
    },
    timestamp: dayjs().subtract(40, 'd').subtract(3, 'y').unix(),
    unread: false,
    category: NotificationCategory.NEW_FOLLOWERS,
  },
]

const MOCK_CREATORS = [
  {
    uid: 1,
    name: 'Katy Perry',
    username: 'katyperry',
    avatarImg: `https://picsum.photos/480/480?nocache=${Math.random()}`,
    bannerImg: `https://picsum.photos/1000/480?nocache=${Math.random()}`,
    walletAddress: '0x1F74d486347fb546kd230c0bC618148sC238',
    followers: 2944,
    following: 0,
    isFollowed: false,
  },
  {
    uid: 2,
    name: 'Tim Cook',
    username: 'timcook',
    avatarImg: `https://picsum.photos/480/480?nocache=${Math.random()}`,
    bannerImg: `https://picsum.photos/1000/480?nocache=${Math.random()}`,
    walletAddress: '0x1F74d486347fb546kd230c0bC618148sC238',
    followers: 50,
    following: 10,
    isFollowed: true,
  },
  {
    uid: 3,
    name: 'Elon Musk',
    username: 'elon',
    avatarImg: `https://picsum.photos/480/480?nocache=${Math.random()}`,
    bannerImg: `https://picsum.photos/1000/480?nocache=${Math.random()}`,
    walletAddress: '0x1F74d486347fb546kd230c0bC618148sC238',
    followers: 69000,
    following: 420,
    isFollowed: false,
  },
  {
    uid: 4,
    name: 'Jeff',
    username: 'jeff',
    avatarImg: `https://picsum.photos/480/480?nocache=${Math.random()}`,
    bannerImg: `https://picsum.photos/1000/480?nocache=${Math.random()}`,
    walletAddress: '0x1F74d486347fb546kd230c0bC618148sC238',
    followers: 200,
    following: 80,
    isFollowed: true,
  },
  {
    uid: 5,
    name: 'Trump',
    username: 'trump',
    avatarImg: `https://picsum.photos/480/480?nocache=${Math.random()}`,
    bannerImg: `https://picsum.photos/1000/480?nocache=${Math.random()}`,
    walletAddress: '0x1F74d486347fb546kd230c0bC618148sC238',
    followers: 400,
    following: 620,
    isFollowed: true,
  },
]

const MOCK_WALLETS = [
  {
    id: 1,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Trail',
    currencyCode: 'TRAIL',
    amountCoins: '200.3743423',
    amountUSD: '2,193.1483',
  },
  {
    id: 2,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Monkey coin',
    currencyCode: 'MNK',
    amountCoins: '8237.29',
    amountUSD: '1,204.4729',
  },
  {
    id: 3,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Dogecoin',
    currencyCode: 'DGC',
    amountCoins: '82.3729',
    amountUSD: '1,204.4729',
  },
  {
    id: 4,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Etherium',
    currencyCode: 'ETH',
    amountCoins: '82.3729',
    amountUSD: '1,204.4729',
  },
  {
    id: 5,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Bitcoin',
    currencyCode: 'BTC',
    amountCoins: '82.3729',
    amountUSD: '1,204.4729',
  },
  {
    id: 6,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Ape coin',
    currencyCode: 'APC',
    amountCoins: '82.3729',
    amountUSD: '1,204.4729',
  },
  {
    id: 7,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Chia',
    currencyCode: 'CHI',
    amountCoins: '82.3729',
    amountUSD: '1,204.4729',
  },
  {
    id: 8,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Binance coin',
    currencyCode: 'BNC',
    amountCoins: '82.3729',
    amountUSD: '1,204.4729',
  },
  {
    id: 9,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Stablecoin',
    currencyCode: 'USDC',
    amountCoins: '82.3729',
    amountUSD: '1,204.4729',
  },
  {
    id: 10,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    name: 'Next',
    currencyCode: 'NXT',
    amountCoins: '82.3729',
    amountUSD: '1,204.4729',
  },
]

const MOCK_TRANSACTIONS = [
  {
    id: 16383816,
    type: TransactionType.CONVERT,
    status: TransactionStatus.COMPLETED,
    timestampCreated: dayjs().subtract(2, 'm').unix(),
    amountMain: {
      amount: '20.2833',
      currency: 'KPE',
    },
    amountExtra: {
      isNegative: true,
      amount: '1.2810',
      currency: 'TRAIL',
    },
  },
  {
    id: 263464718,
    type: TransactionType.CONVERT,
    status: TransactionStatus.IN_PROCESS,
    timestampCreated: dayjs().subtract(2, 'd').add(2, 'm').unix(),
    amountMain: {
      amount: '20.2833',
      currency: 'KPE',
    },
    amountExtra: {
      isNegative: true,
      amount: '1.2810',
      currency: 'TRAIL',
    },
  },
  {
    id: 848159291,
    type: TransactionType.SEND,
    status: TransactionStatus.IN_PROCESS,
    timestampCreated: dayjs().subtract(3, 'd').subtract(22, 'm').unix(),
    amountMain: {
      isNegative: true,
      amount: '84.2282',
      currency: 'TRAIL',
    },
    user: {
      name: '@elonmusk',
      profileUrl: '/',
    },
  },
  {
    id: 848596291,
    type: TransactionType.RECEIVE,
    status: TransactionStatus.IN_PROCESS,
    timestampCreated: dayjs().subtract(6, 'd').subtract(608, 'm').unix(),
    amountMain: {
      isNegative: true,
      amount: '84.2282',
      currency: 'TRAIL',
    },
    user: {
      name: '@katyperry',
      profileUrl: '/',
    },
  },
  {
    id: 399434912,
    type: TransactionType.CONVERT,
    status: TransactionStatus.COMPLETED,
    timestampCreated: dayjs().subtract(6, 'd').subtract(283, 'm').unix(),
    amountMain: {
      amount: '2.6482',
      currency: 'KPE',
    },
    amountExtra: {
      isNegative: true,
      amount: '1.2810',
      currency: 'TRAIL',
    },
  },
  {
    id: 848592921,
    type: TransactionType.RECEIVE,
    status: TransactionStatus.FAILED,
    timestampCreated: dayjs().subtract(12, 'd').add(54, 'm').unix(),
    amountMain: {
      isNegative: true,
      amount: '84.2282',
      currency: 'TRAIL',
    },
    user: {
      name: '@timcook',
      profileUrl: '/',
    },
  },
  {
    id: 8485769292,
    type: TransactionType.SEND,
    status: TransactionStatus.IN_PROCESS,
    timestampCreated: dayjs().subtract(16, 'd').add(242, 'm').unix(),
    amountMain: {
      isNegative: true,
      amount: '84.2282',
      currency: 'TRAIL',
    },
    user: {
      name: '@gaben',
      profileUrl: '/',
    },
  },
  {
    id: 848592092,
    type: TransactionType.SEND,
    status: TransactionStatus.COMPLETED,
    timestampCreated: dayjs().subtract(20, 'd').subtract(156, 'm').unix(),
    amountMain: {
      isNegative: true,
      amount: '84.2282',
      currency: 'TRAIL',
    },
    user: {
      name: '@garry',
      profileUrl: '/',
    },
  },
  {
    id: 236346718,
    type: TransactionType.CONVERT,
    status: TransactionStatus.COMPLETED,
    timestampCreated: dayjs().subtract(24, 'd').add(823, 'm').unix(),
    amountMain: {
      amount: '20.2833',
      currency: 'KPE',
    },
    amountExtra: {
      isNegative: true,
      amount: '1.2810',
      currency: 'TRAIL',
    },
  },
  {
    id: 264346718,
    type: TransactionType.CONVERT,
    status: TransactionStatus.IN_PROCESS,
    timestampCreated: dayjs().subtract(120, 'd').add(444, 'm').unix(),
    amountMain: {
      amount: '20.2833',
      currency: 'KPE',
    },
    amountExtra: {
      isNegative: true,
      amount: '1.2810',
      currency: 'TRAIL',
    },
  },
  {
    id: 263467718,
    type: TransactionType.CONVERT,
    status: TransactionStatus.FAILED,
    timestampCreated: dayjs().subtract(180, 'd').unix(),
    amountMain: {
      amount: '20.2833',
      currency: 'KPE',
    },
    amountExtra: {
      isNegative: true,
      amount: '1.2810',
      currency: 'TRAIL',
    },
  },
]

const MOCK_EXCHANGE_COURSES = {
  TRAIL: { TRAIL: 1, MNK: 2.5, DGC: 10 },
  MNK: { TRAIL: 0.4, MNK: 1, DGC: 4 },
  DGC: { TRAIL: 0.1, MNK: 0.25, DGC: 1 },
}

const MOCK_RECIPIENTS = [
  {
    name: 'elonmusk',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    walletAddress: '0x1234',
    walletFundsTrail: '42,540',
  },
  {
    name: 'elonsmith',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    walletAddress: '0xabcd',
    walletFundsTrail: '440',
  },
  {
    name: 'elongreen',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    walletAddress: '0x5678',
    walletFundsTrail: '460',
  },
  {
    name: 'elongrey',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    walletAddress: '0x1235',
    walletFundsTrail: '2,800',
  },
  {
    name: 'elonjobs',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    walletAddress: '0x1248',
    walletFundsTrail: '3,200',
  },
  {
    name: 'katyperry',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    walletAddress: '0x0001',
    walletFundsTrail: '2,2135',
  },
]

const MOCK_COUNTRIES = [
  {
    id: 1,
    name: 'France',
    img: `https://flagcdn.com/w160/fr.png`,
  },
  {
    id: 2,
    name: 'United Kingdom',
    img: `https://flagcdn.com/w160/gb.png`,
  },
  {
    id: 3,
    name: 'Spain',
    img: `https://flagcdn.com/w160/es.png`,
  },
  {
    id: 4,
    name: 'Germany',
    img: `https://flagcdn.com/w160/de.png`,
  },
  {
    id: 5,
    name: 'United States',
    img: `https://flagcdn.com/w160/us.png`,
  },
  {
    id: 6,
    name: 'China',
    img: `https://flagcdn.com/w160/cn.png`,
  },
  {
    id: 7,
    name: 'Italy',
    img: `https://flagcdn.com/w160/it.png`,
  },
  {
    id: 8,
    name: 'Japan',
    img: `https://flagcdn.com/w160/jp.png`,
  },
  {
    id: 9,
    name: 'Brazil',
    img: `https://flagcdn.com/w160/br.png`,
  },
  {
    id: 10,
    name: 'Korea',
    img: `https://flagcdn.com/w160/kr.png`,
  },
  {
    id: 11,
    name: 'Ukraine',
    img: `https://flagcdn.com/w160/ua.png`,
  },
  {
    id: 12,
    name: 'Cameroon',
    img: `https://flagcdn.com/w160/cm.png`,
  },
]

const MOCK_CITIES = [
  { id: 1, name: 'Barcelona', countryId: 3 },
  { id: 2, name: 'Madrid', countryId: 3 },
  { id: 3, name: 'Valencia', countryId: 3 },
  { id: 4, name: 'Palma', countryId: 3 },
  { id: 5, name: 'London', countryId: 2 },
  { id: 6, name: 'Glasgow', countryId: 2 },
  { id: 7, name: 'Manchester', countryId: 2 },
  { id: 8, name: 'Newcastle', countryId: 2 },
  { id: 9, name: 'Paris', countryId: 1 },
  { id: 10, name: 'Lyon', countryId: 1 },
  { id: 11, name: 'Grenoble', countryId: 1 },
  { id: 12, name: 'Lille', countryId: 1 },
  { id: 13, name: 'Marseille', countryId: 1 },
  { id: 14, name: 'Strasbourg', countryId: 1 },
  { id: 15, name: 'Toulouse', countryId: 1 },
  { id: 16, name: 'Nice', countryId: 1 },
  { id: 17, name: 'Bordeaux', countryId: 1 },
  { id: 18, name: 'Montpellier', countryId: 1 },
  { id: 19, name: 'Aix-en-Provence', countryId: 1 },
  { id: 20, name: 'Rennes', countryId: 1 },
  { id: 21, name: 'Avignon', countryId: 1 },
  { id: 22, name: 'Cannes', countryId: 1 },
]

const MOCK_AUCTION_DETAILS_ITEM = {
  name: 'Flatiron Building',
  img: `https://picsum.photos/1000/1000?nocache=${Math.random()}`,
  numberInStock: 10,
  numberTotal: 15,
  price: 12.4627,
  currencyName: 'katyperry coins',
  priceUsd: '1,204.4729',
  percentOfSalesToCreator: 15,
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo tellus pretium consectetur morbi diam nullam eu. Sed volutpat pharetra consectetur scelerisque viverra gravida magna enim.',
  owner: {
    name: 'katyperry',
    profileUrl: '/',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  creator: {
    name: 'elonmusk',
    profileUrl: '/',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  collection: {
    country: 'USA',
    city: 'New York',
    img: `https://flagcdn.com/w160/us.png`,
    collectionUrl: '/',
  },
  endsAtTimestamp: dayjs().add(16, 'h').add(40, 'm').add(12, 's').unix(),
  history: [
    {
      type: AuctionHistoryItemType.BID_PLACED,
      user: {
        name: 'johnsmith',
        img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
        profileUrl: '/',
      },
      price: {
        amount: '12.4627',
        currencyName: 'katyperry coins',
      },
      timestamp: dayjs().subtract(2, 'd').subtract(6433, 'm').unix(),
    },
    {
      type: AuctionHistoryItemType.LISTED,
      user: {
        name: 'katyperry',
        img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
        profileUrl: '/',
      },
      price: {
        amount: '8.00',
        currencyName: 'katyperry coins',
      },
      timestamp: dayjs().subtract(3, 'd').subtract(30, 'm').unix(),
    },
    {
      type: AuctionHistoryItemType.MINTED,
      user: {
        name: 'katyperry',
        img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
        profileUrl: '/',
      },
      timestamp: dayjs().subtract(3, 'd').subtract(32, 'm').unix(),
    },
  ],
}

const MOCK_COMMUNITY_UPVOTE_ITEMS = [
  {
    place: 1,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 1439,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    place: 2,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 219,
    hasReceivedVote: true,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    place: 3,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 205,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    place: 4,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 178,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    place: 5,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 167,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    place: 6,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 132,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    place: 7,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 120,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    place: 8,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 112,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    place: 9,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 108,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    place: 10,
    name: 'Katy Perry',
    username: 'katyperry',
    profileURL: '/',
    numberOfVotes: 106,
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
]

const MOCK_FOLLOWERS = [
  {
    id: 1,
    name: 'Katty Perry',
    username: 'kattyperry',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    id: 2,
    name: 'Jim Beam',
    username: 'jimbeam',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 3,
    name: 'Elon Musk',
    username: 'elonmusk',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    id: 4,
    name: 'Elon Dusk',
    username: 'elondusk',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    id: 5,
    name: 'Elon Husk',
    username: 'elonhusk',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    id: 6,
    name: 'Tim Cook',
    username: 'timcook',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    id: 7,
    name: 'Jeff Bezos',
    username: 'jeff',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    id: 8,
    name: 'Bill Gates',
    username: 'microsoft',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    id: 9,
    name: 'Mark Zuckerberg',
    username: 'data.stealer',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  {
    id: 10,
    name: 'John',
    username: 'justjohn',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
]

const MOCK_FOLLOWING = [
  {
    id: 11,
    name: 'Michael Townley',
    username: 'mike',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 12,
    name: 'Trevor Philips',
    username: 'philips.industries',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 13,
    name: 'Franklin Clinton',
    username: 'employee.of.the.month',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 14,
    name: 'Lamar Davis',
    username: 'lamar',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 15,
    name: 'Dave Northon',
    username: 'dave',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 16,
    name: 'Simeon Yetarian',
    username: 'bestcars',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 1,
    name: 'Katty Perry',
    username: 'kattyperry',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 2,
    name: 'Jim Beam',
    username: 'jimbeam',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 3,
    name: 'Elon Musk',
    username: 'elonmusk',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 4,
    name: 'Elon Dusk',
    username: 'elondusk',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 5,
    name: 'Elon Husk',
    username: 'elonhusk',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 6,
    name: 'Tim Cook',
    username: 'timcook',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 7,
    name: 'Jeff Bezos',
    username: 'jeff',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 8,
    name: 'Bill Gates',
    username: 'microsoft',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 9,
    name: 'Mark Zuckerberg',
    username: 'data.stealer',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
  {
    id: 10,
    name: 'John',
    username: 'justjohn',
    img: `https://picsum.photos/96/96?nocache=${Math.random()}`,
    isFollowed: true,
  },
]

export {
  MOCK_PROFILE_PAGE_AUCTIONS,
  MOCK_NOTIFICATIONS,
  MOCK_CREATORS,
  MOCK_WALLETS,
  MOCK_TRANSACTIONS,
  MOCK_EXCHANGE_COURSES,
  MOCK_RECIPIENTS,
  MOCK_COUNTRIES,
  MOCK_CITIES,
  MOCK_AUCTION_DETAILS_ITEM,
  MOCK_COMMUNITY_UPVOTE_ITEMS,
  MOCK_FOLLOWERS,
  MOCK_FOLLOWING,
}
