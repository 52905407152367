import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import Icon from '~components/ui/Icon'
import Menu from '~components/general/Menu'
import Social from '~components/general/Social'

import * as style from './style.module.scss'

const fullYear = new Date().getFullYear()

/**
 * Footer
 * @see [Polkatrail Figma/Components]{@link https://www.figma.com/file/7RSB93DI7xsUJjhj7QDPs9/Polkatrail-Web-App?node-id=1599%3A12168}
 */
const Footer = (props) => {
  const { siteTitle } = props

  return (
    <Container as="footer" className={style.footer}>
      <div className={style.footerUpper}>
        <Link to="/" className={style.logoLink} title={siteTitle}>
          <Icon name="icon-logo" size={[123, 30]} />
        </Link>
        <Menu className={style.navMenu} variant="footer" />
        <Social />
      </div>
      <div className={style.divider} tabIndex={-1} />
      <div className={style.footerLower}>
        <p>PolkaTrail brings DeFi and NFT tech into travel experience</p>
        <p>
          © {fullYear} {siteTitle}. All rights reserved
        </p>
      </div>
    </Container>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string.isRequired,
}

export default Footer
