import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { upperFirst } from 'lodash'

import * as style from './NotificationItem.module.scss'
import { NotificationType } from '~utils/enums'
import { timestampToRelativeTime } from '~utils'
import Icon from '~components/ui/Icon'

const getNotificationText = (notification) => {
  const { type, user, auction, timestamp } = notification

  switch (type) {
    case NotificationType.PUT_ON_SALE:
      return (
        <>
          <span className={style.userName}>
            <Link to={user.profileUrl}>{user.name}</Link>
            {!!user.isVerified && (
              <Icon
                name="icon-verified"
                className={style.iconVerified}
                size={[16, 15]}
              />
            )}
          </span>{' '}
          <span>
            put on sale <Link to={auction.url}>{auction.name}</Link>{' '}
            {timestampToRelativeTime(timestamp)}
          </span>
        </>
      )
    case NotificationType.BID_PLACED:
      return (
        <>
          <span className={style.userName}>
            <Link to={user.profileUrl}>{user.name}</Link>
            {!!user.isVerified && (
              <Icon
                name="icon-verified"
                size={[16, 15]}
                className={style.iconVerified}
              />
            )}
          </span>{' '}
          <span>
            placed a bid on <Link to={auction.url}>{auction.name}</Link>{' '}
            {timestampToRelativeTime(timestamp)}
          </span>
        </>
      )
    case NotificationType.NEW_FOLLOWER:
      return (
        <>
          <span className={style.userName}>
            <Link to={user.profileUrl}>{user.name}</Link>
            {!!user.isVerified && (
              <Icon
                name="icon-verified"
                size={[16, 15]}
                className={style.iconVerified}
              />
            )}
          </span>{' '}
          <span>
            started following you {timestampToRelativeTime(timestamp)}
          </span>
        </>
      )
    default:
      return ''
  }
}

/**
 * NotificationItem component
 */
const NotificationItem = (props) => {
  const { className, notification, variant, ...rest } = props

  const { user, unread } = notification

  return (
    <li
      {...rest}
      className={classNames(
        style.notificationItem,
        {
          [style.unread]: unread,
          [style[`variant${upperFirst(variant)}`]]: variant,
        },
        className
      )}
    >
      <Link to="/profile/" className={style.avatarWrapper}>
        <div className={style.avatar}>{user.avatarImg}</div>
      </Link>
      <p className={style.text}>{getNotificationText(notification)}</p>
    </li>
  )
}

NotificationItem.defaultProps = {
  className: '',
  variant: null,
}

NotificationItem.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['popup', 'page']),
}

export default NotificationItem
