import React from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Nav } from 'react-bootstrap'
import { Link } from 'gatsby'
import classNames from 'classnames'

import * as style from './NavMenuMobile.module.scss'
import { TOP_NAV_MENU } from '~utils/constants'
import InputSearch from '~components/ui/InputSearch'
import WalletButton from '../WalletButton'
import Route from '~routes'
import ButtonIcon from '~components/ui/ButtonIcon'

/**
 * NavMenuMobile component
 */
const NavMenuMobile = React.forwardRef((props, ref) => {
  const { className, isLoggedIn, activePage, onConnectWallet, ...rest } = props

  return (
    <div {...rest} className={className} ref={ref}>
      <Container className={style.menuMobile}>
        <div className={style.gridUpper}>
          <Link to={Route.NOTIFICATIONS}>
            <ButtonIcon
              iconName="icon-bell"
              aria-label="Notifications"
              badgeText={8}
              isActivePage={activePage === Route.NOTIFICATIONS}
            />
          </Link>
          <WalletButton
            amount={200.3743423}
            currency="trail"
            isSelected={activePage === Route.WALLET}
            linkTo={Route.WALLET}
          />
        </div>
        <Nav as="ul" variant="headerMobile">
          {TOP_NAV_MENU.map(({ name, link }) => (
            <Nav.Item as="li" key={name}>
              <Link
                to={link}
                className={classNames('nav-link', style.navLink)}
                activeClassName="active"
              >
                {name}
              </Link>
            </Nav.Item>
          ))}
        </Nav>
        {!isLoggedIn ? (
          <Button
            variant="primary"
            size="lg"
            className={style.btn}
            onClick={onConnectWallet}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            variant="primary"
            size="lg"
            className={style.btn}
            as={Link}
            to={Route.CREATE_NFT}
          >
            Create
          </Button>
        )}
        <InputSearch
          name="mobile-menu-search"
          placeholder="Search"
          navigateOnSubmit
          className={style.inpSearch}
        />
      </Container>
    </div>
  )
})

NavMenuMobile.defaultProps = {
  className: '',
  activePage: '',
}

NavMenuMobile.propTypes = {
  className: PropTypes.string,
  activePage: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  onConnectWallet: PropTypes.func.isRequired,
}

export default NavMenuMobile
