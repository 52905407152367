import React, { useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import * as style from './NotificationOverlay.module.scss'
import ButtonIcon from '~components/ui/ButtonIcon'
import NotificationItem from '../NotificationItem'
import { MOCK_NOTIFICATIONS } from '~utils/mocks'
import Route from '~routes'

/**
 * NotificationOverlay component
 */
const NotificationOverlay = (props) => {
  const { isActivePage } = props

  const overlayTargetRef = useRef()

  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  const toggleOverlayVisibility = () => {
    setIsOverlayVisible((prevState) => !prevState)
  }

  return (
    <>
      <ButtonIcon
        iconName="icon-bell"
        aria-label="Notifications"
        badgeText={8}
        isOverlayVisible={isOverlayVisible}
        isActivePage={isActivePage}
        ref={overlayTargetRef}
        onClick={toggleOverlayVisibility}
      />
      <Overlay
        target={overlayTargetRef.current}
        placement="bottom"
        show={isOverlayVisible}
        onHide={() => setIsOverlayVisible(false)}
        rootClose
        trigger="click"
      >
        {({ placement, arrowProps, show: _show, popper, ...overlayProps }) => (
          <Popover
            id="notification-popover"
            className={style.popover}
            {...overlayProps}
          >
            <Popover.Header as="h3">Notifications</Popover.Header>
            <Popover.Body className={style.body}>
              <ul className={style.notificationList}>
                {MOCK_NOTIFICATIONS.slice(0, 4).map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                  />
                ))}
              </ul>
              <Link to={Route.NOTIFICATIONS} className={style.btnFooter}>
                View All
              </Link>
            </Popover.Body>
          </Popover>
        )}
      </Overlay>
    </>
  )
}

NotificationOverlay.defaultProps = {
  isActivePage: false,
}

NotificationOverlay.propTypes = {
  isActivePage: PropTypes.bool,
}

export default NotificationOverlay
