import { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'

const useMobileNavMenu = () => {
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)

  const { width, ref: mobileNavRef } = useResizeDetector({
    handleHeight: false,
  })
  const integerWidth = Math.floor(width)

  const enableBodyScroll = () =>
    document.body.classList.remove('scrollDisabled')

  const disableBodyScroll = () => document.body.classList.add('scrollDisabled')

  useEffect(() => () => enableBodyScroll(), [])

  useEffect(() => {
    if (isMobileNavVisible && integerWidth >= 1440) {
      setIsMobileNavVisible(false)
      enableBodyScroll()
    }
  }, [integerWidth])

  const toggleMobileNavVisibility = () => {
    if (isMobileNavVisible) {
      setIsMobileNavVisible(false)
      enableBodyScroll()
    } else {
      setIsMobileNavVisible(true)
      disableBodyScroll()
    }
  }

  return {
    mobileNavRef,
    toggleMobileNavVisibility,
    isMobileNavVisible,
    setIsMobileNavVisible,
  }
}

export default useMobileNavMenu
