import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './ConnectWalletItem.module.scss'
import Icon from '~components/ui/Icon'

/**
 * ConnectWalletItem component
 */
const ConnectWalletItem = (props) => {
  const { className, name, icon, isLoading, ...rest } = props

  return (
    <button
      {...rest}
      type="button"
      className={classNames(
        style.connectWalletItem,
        { [style.loading]: isLoading },
        className
      )}
    >
      <div className={style.blockName}>
        {isLoading && (
          <Icon
            name="icon-wallet-connection-loading"
            size={18}
            className={style.iconLoading}
          />
        )}
        <p className={classNames({ [style.textLoading]: isLoading })}>
          {!isLoading ? name : 'Connecting'}
        </p>
      </div>
      <Icon name={icon} size={40} />
    </button>
  )
}

ConnectWalletItem.defaultProps = {
  className: '',
  isLoading: false,
}

ConnectWalletItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
}

export default ConnectWalletItem
