const Route = {
  HOMEPAGE: '/',
  PROFILE: '/profile/',
  NOTIFICATIONS: '/notifications/',
  SEARCH: '/search/',
  WALLET: '/wallet/',
  FOLLOWING: '/following/',
  CITY_COLLECTIBLES: '/city-collectibles/',
  AUCTION: '/auction/',
  LIVE_AUCTIONS: '/live-auctions/',
  COMMUNITY_UPVOTE: '/community-upvote/',
  CREATE_NFT: '/create/',
  MINT_NFT: '/mint/',
}

export default Route
