/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import '~styles/app.scss'
import Footer from '~components/general/Footer'
import Header from '~components/general/Header'
import * as style from './style.module.scss'

const Layout = (props) => {
  const { children, siteTitle, isLoggedIn, activePage } = props

  return (
    <div className={style.layout}>
      <Header
        siteTitle={siteTitle}
        isLoggedIn={isLoggedIn}
        activePage={activePage}
      />
      <main className={style.main}>{children}</main>
      <Footer siteTitle={siteTitle} />
    </div>
  )
}

Layout.defaultProps = {
  isLoggedIn: false,
  activePage: '',
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  siteTitle: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool,
  activePage: PropTypes.string,
}

export default Layout
