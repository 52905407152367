import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Form, FormControl, InputGroup } from 'react-bootstrap'
import { navigate } from 'gatsby'

import { useLocation } from '@gatsbyjs/reach-router'
import * as style from './InputSearch.module.scss'
import Icon from '~components/ui/Icon'
import DropdownSelect from '~components/ui/DropdownSelect'
import Route from '~routes'
import { SearchType } from '~utils/enums'

/**
 * InputSearch component
 * Implements custom "focus" state management
 * @see [Polkatrail Figma/Components]{@link https://www.figma.com/file/7RSB93DI7xsUJjhj7QDPs9/Polkatrail-Web-App?node-id=1402%3A2162}
 */
const InputSearch = (props) => {
  const {
    className,
    label,
    id,
    name,
    placeholder,
    disabled,
    variant,
    activeSearchType,
    setActiveSearchType,
    onChange,
    onClear,
    value,
    navigateOnSubmit,
    isSearchFilter,
    ...rest
  } = props

  const [isFocused, setIsFocused] = useState(false)

  const inputRef = useRef()

  const location = useLocation()

  const [searchInputValue, setSearchInputValue] = useState(() => {
    if (isSearchFilter) {
      if (value) return value
      return ''
    }

    if (typeof window === 'undefined') return ''

    const params = new URLSearchParams(location.search.substring(1))
    return params.get('q') ?? ''
  })

  const handleSearchInputChange = (e) => {
    setSearchInputValue(e.target.value)
    onChange(e)
  }

  const clearSearchInput = () => {
    setSearchInputValue('')
    onClear()
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <form
      {...rest}
      className={classNames(
        style.searchInput,
        {
          [style.focused]: isFocused,
          [style.disabled]: disabled,
          [style[variant]]: variant,
        },
        className
      )}
      onClick={() => {
        inputRef.current.focus()
      }}
      onSubmit={(e) => {
        e.preventDefault()
        const searchString = e.target?.[name]?.value

        if (navigateOnSubmit) navigate(`${Route.SEARCH}?q=${searchString}`)
      }}
    >
      {!!label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      <InputGroup className={style.inputGroup}>
        <InputGroup.Text
          className={style.inputGroupText}
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Icon name="icon-search" size={24} style={{ padding: '3px' }} />
        </InputGroup.Text>
        <FormControl
          name={name}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={style.formControl}
          ref={inputRef}
          value={value ?? searchInputValue}
          onChange={handleSearchInputChange}
        />
        {variant === 'extended' ? (
          <InputGroup.Text
            className={classNames(
              style.inputGroupText,
              style.inputGroupDropdown
            )}
            onMouseDown={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <DropdownSelect
              onClick={(e) => e.stopPropagation()}
              activeValue={activeSearchType}
              setActiveValue={setActiveSearchType}
              values={Object.values(SearchType)}
              valuesAsPlainArray
            />
          </InputGroup.Text>
        ) : (
          <InputGroup.Text
            className={classNames(
              style.inputGroupText,
              style.clearButtonContainer
            )}
            onMouseDown={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <button
              aria-label="Clear"
              type="button"
              className={classNames(style.btnClear, {
                [style.btnClearVisible]:
                  (!disabled && isFocused) ||
                  (isSearchFilter ? !!value || !!searchInputValue : false),
              })}
              onClick={(e) => {
                // Placeholder onClick
                e.stopPropagation()
                inputRef.current.blur()
                clearSearchInput()
              }}
            >
              <Icon name="icon-clear" size={24} />
            </button>
          </InputGroup.Text>
        )}
      </InputGroup>
    </form>
  )
}

InputSearch.defaultProps = {
  className: '',
  label: null,
  placeholder: null,
  name: null,
  id: null,
  disabled: null,
  variant: null,
  activeSearchType: null,
  setActiveSearchType: () => {},
  onChange: () => {},
  onClear: () => {},
  value: null,
  navigateOnSubmit: false,
  isSearchFilter: false,
}

InputSearch.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['extended', 'transactions']),
  activeSearchType: PropTypes.object,
  setActiveSearchType: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.any,
  navigateOnSubmit: PropTypes.bool,
  isSearchFilter: PropTypes.bool,
}

export default InputSearch
