import urlRegex from 'url-regex'
import Route from '~routes'

const SOCIAL_MEDIA_LINKS = [
  { href: '#', icon: 'telegram' },
  { href: '#', icon: 'linkedin', title: 'LinkedIn' },
  { href: '#', icon: 'twitter' },
  { href: '#', icon: 'medium-dot-com', title: 'Medium' },
]

const SOCIAL_MEDIA_INPUTS = [
  { name: 'twitter' },
  { name: 'facebook' },
  { name: 'discord' },
  { name: 'instagram' },
  { name: 'twitch' },
  { name: 'tiktok', label: 'TikTok' },
]

const INITIAL_DATE_RANGE_ARRAY = [
  { startDate: null, endDate: null, key: 'selection' },
]

const TOP_NAV_MENU = [
  {
    name: 'Explore',
    link: Route.HOMEPAGE,
  },
  {
    name: 'Following',
    link: Route.FOLLOWING,
  },
  {
    name: 'City Collectibles',
    link: Route.CITY_COLLECTIBLES,
  },
  {
    name: 'My Profile',
    link: Route.PROFILE,
    mobileOnly: true,
  },
]

const TOAST_CONTENTS = {
  success: {
    heading: 'Success!',
    text: 'Transaction was proceeded successfully',
  },
  error: {
    heading: 'Error',
    text: 'Unable to finish transaction. Please, try again later',
  },
  balanceExceeded: {
    heading: 'Balance exceeded!',
    text: 'This operation requires more funds that are present on the selected wallet',
  },
}

const AUCTION_MENU_ITEMS = [
  { text: 'Edit', icon: 'menu-edit' },
  {
    text: 'Share',
    icon: 'menu-share',
  },
  {
    text: 'Delete',
    icon: 'menu-delete',
    variant: 'danger',
  },
]

const RHF_URL_RULE = {
  pattern: {
    value: urlRegex({ strict: false }),
    message: 'Please, enter a valid url',
  },
}

const RHF_TWITTER_RULE = {
  pattern: {
    value: /^(https:\/\/twitter\.com\/).+/,
    message: 'Please, enter a valid Tweet URL',
  },
}

export {
  SOCIAL_MEDIA_LINKS,
  SOCIAL_MEDIA_INPUTS,
  INITIAL_DATE_RANGE_ARRAY,
  TOP_NAV_MENU,
  TOAST_CONTENTS,
  AUCTION_MENU_ITEMS,
  RHF_URL_RULE,
  RHF_TWITTER_RULE,
}
