import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link } from 'gatsby'
import * as style from './WalletButton.module.scss'

/**
 * WalletButton component
 * @see [Polkatrail Figma/Components]{@link https://www.figma.com/file/7RSB93DI7xsUJjhj7QDPs9/Polkatrail-Web-App?node-id=1559%3A8779}
 */
const WalletButton = (props) => {
  const { amount = 0, currency, className, isSelected, linkTo, ...rest } = props

  const [amountWhole, amountDecimal] = amount.toString().split('.')

  return (
    <Link
      {...rest}
      className={classNames(
        style.walletButton,
        { [style.selected]: isSelected },
        className
      )}
      to={linkTo}
    >
      <span className={style.textAmountWhole}>{amountWhole}.</span>
      <span className={style.textAmountDecimal}>{amountDecimal}</span>{' '}
      <span className={style.textCurrency}>{currency}</span>
    </Link>
  )
}

WalletButton.defaultProps = {
  className: '',
  isSelected: false,
}

WalletButton.propTypes = {
  className: PropTypes.string,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
}

export default WalletButton
