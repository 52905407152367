import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './ButtonIcon.module.scss'
import Icon from '~components/ui/Icon'

/**
 * ButtonIcon component
 * @see [Polkatrail Figma/Components]{@link https://www.figma.com/file/7RSB93DI7xsUJjhj7QDPs9/Polkatrail-Web-App?node-id=1739%3A2163}
 */
const ButtonIcon = React.forwardRef((props, ref) => {
  const {
    className,
    iconName,
    badgeText,
    isActivePage,
    isOverlayVisible,
    ...rest
  } = props

  return (
    <button
      {...rest}
      className={classNames(
        style.buttonIcon,
        { [style.hover]: isOverlayVisible },
        { [style.selected]: isActivePage },
        className
      )}
      type="button"
      ref={ref}
    >
      {badgeText && <div className={style.badge}>{badgeText}</div>}
      <Icon name={iconName} size={16} />
    </button>
  )
})

ButtonIcon.defaultProps = {
  className: '',
  badgeText: null,
  isActivePage: false,
  isOverlayVisible: false,
}

ButtonIcon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  'aria-label': PropTypes.string.isRequired,
  badgeText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActivePage: PropTypes.bool,
  isOverlayVisible: PropTypes.bool,
}

export default ButtonIcon
