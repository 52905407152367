import React from 'react'
import { Container } from 'react-bootstrap'

import Layout from '../components/general/Layout'
import S from '../components/seo'

/* TODO: 404 page */
const NotFoundPage = (props) => (
  <Layout {...props}>
    <Container>
      <S title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
