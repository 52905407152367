const AuctionCategory = {
  ON_SALE: { key: 'onSale', name: 'On Sale' },
  CREATED: { key: 'created', name: 'Created' },
  SOLD: { key: 'sold', name: 'Sold' },
  BIDDING: { key: 'bidding', name: 'Bidding', hasNotification: true },
  COLLECTIBLES: { key: 'collectibles', name: 'Collectibles' },
  MY_PROPERTIES: { key: 'myProperties', name: 'My Properties' },
}

const AuctionStatus = {
  LIVE: { key: 'live', name: 'Live' },
  UPCOMING: { key: 'upcoming', name: 'Upcoming' },
  COMPLETED: { key: 'completed', name: 'Completed' },
}

const NotificationType = {
  BID_PLACED: 'bidPlaced',
  PUT_ON_SALE: 'putOnSale',
  NEW_FOLLOWER: 'newFollower',
}

const NotificationCategory = {
  MY_NFTS: 'My NFTs',
  BIDS: 'Bids',
  NEW_FOLLOWERS: 'New followers',
  FOLLOWING_ACTIONS: 'Following actions',
}

const SearchType = {
  NFTS: 'NFTs',
  PROPERTIES: 'Properties',
  CREATOR: 'Creator',
  CITY_COLLECTIBLES: 'City Collectibles',
}

const TransactionStatus = {
  COMPLETED: 'Completed',
  IN_PROCESS: 'In process',
  FAILED: 'Failed',
}

const TransactionType = {
  CONVERT: 'convert',
  SEND: 'send',
  RECEIVE: 'receive',
}

const AuctionHistoryItemType = {
  BID_PLACED: 'bid placed',
  LISTED: 'listed',
  MINTED: 'minted',
}

const LiveAuctionsSortType = {
  RECENTLY_ADDED: 'Recently Added',
  HIGHEST_PRICE_FIRST: 'Highest Price First',
  LOWEST_PRICE_FIRST: 'Lowest Price First',
}

export {
  AuctionCategory,
  NotificationType,
  NotificationCategory,
  AuctionStatus,
  SearchType,
  TransactionStatus,
  TransactionType,
  AuctionHistoryItemType,
  LiveAuctionsSortType,
}
