import React from 'react'
import { Link } from 'gatsby'
import { Nav } from 'react-bootstrap'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { TOP_NAV_MENU } from '~utils/constants'

// eslint-disable-next-line react/prop-types
const Menu = (props) => {
  const { className, variant, ...rest } = props

  return (
    <Nav
      {...rest}
      className={cn(`nav-${variant}`, className)}
      as="ul"
      defaultActiveKey="#item-1"
    >
      {TOP_NAV_MENU.map(
        ({ name, link, mobileOnly }) =>
          !mobileOnly && (
            <Nav.Item as="li" key={name}>
              <Nav.Link as={Link} to={link} activeClassName="active">
                {name}
              </Nav.Link>
            </Nav.Item>
          )
      )}
    </Nav>
  )
}

Menu.defaultProps = {
  className: '',
  variant: 'header',
}

Menu.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['header', 'footer']),
}

export default Menu
