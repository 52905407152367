/* eslint camelcase: 0 */
import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classNames from 'classnames'

import Menu from '~components/general/Menu'
import InputSearch from '~components/ui/InputSearch'
import Icon from '~components/ui/Icon'
import ButtonIcon from '~components/ui/ButtonIcon'
import Avatar from '~components/general/Avatar'
import WalletButton from '../WalletButton'
import * as style from './Header.module.scss'
import NotificationOverlay from '../NotificationOverlay'
import NavMenuMobile from '~components/general/NavMenuMobile'
import Route from '~routes'
import ConnectWalletModal from '~components/general/ConnectWalletModal'
import useMobileNavMenu from '~hooks/useMobileNavMenu'

const noCache = Math.random()
/**
 * Header
 * @see [Polkatrail Figma/Components]{@link https://www.figma.com/file/7RSB93DI7xsUJjhj7QDPs9/Polkatrail-Web-App?node-id=1559%3A8722}
 */
const Header = (props) => {
  const { isLoggedIn, siteTitle, activePage } = props

  /* Mobile Nav Menu */
  const { mobileNavRef, isMobileNavVisible, toggleMobileNavVisibility } =
    useMobileNavMenu()

  /* Connect Wallet Modal */
  const [isConnectWalletModalVisible, setIsConnectWalletModalVisible] =
    useState()

  return (
    <>
      {!isLoggedIn && (
        <ConnectWalletModal
          show={isConnectWalletModalVisible}
          onHide={() => setIsConnectWalletModalVisible(false)}
        />
      )}
      <Container as="header" className={style.header}>
        <div className={style.headerSection}>
          <Link to="/" title={siteTitle}>
            <Icon name="icon-logo" size={[123, 30]} />
          </Link>
          {activePage !== Route.SEARCH && (
            <InputSearch
              placeholder="Search"
              name="header-search"
              navigateOnSubmit
              className={style.searchInput}
            />
          )}
        </div>
        <div className={style.headerSection}>
          <Menu className={style.navMenu} />
          <div className={style.userSection}>
            {!isLoggedIn ? (
              <Button
                variant="primary"
                onClick={() => setIsConnectWalletModalVisible(true)}
              >
                Connect Wallet
              </Button>
            ) : (
              <>
                <Button
                  variant="primary"
                  className={style.btnCreate}
                  as={Link}
                  to={Route.CREATE_NFT}
                >
                  Create
                </Button>
                <NotificationOverlay
                  isActivePage={activePage === Route.NOTIFICATIONS}
                />
                <WalletButton
                  amount={200.3743423}
                  currency="trail"
                  isSelected={activePage === Route.WALLET}
                  linkTo={Route.WALLET}
                />
                <Link to={Route.PROFILE} className={style.avatar}>
                  <Avatar isSelected={activePage === Route.PROFILE}>
                    <img
                      src={`https://picsum.photos/96/96?nocache=${noCache}`}
                      alt="avatar"
                      width="100%"
                      height="100%"
                    />
                  </Avatar>
                </Link>
              </>
            )}
          </div>
          <div className={style.btnMenuWrapper}>
            <ButtonIcon
              iconName={`icon-${isMobileNavVisible ? 'menu-close' : 'menu'}`}
              aria-label="menu"
              onClick={toggleMobileNavVisibility}
            />
          </div>
        </div>
      </Container>
      <NavMenuMobile
        className={classNames(style.mobileMenu, {
          [style.visible]: isMobileNavVisible,
        })}
        isLoggedIn={isLoggedIn}
        ref={mobileNavRef}
        activePage={activePage}
        onConnectWallet={() => setIsConnectWalletModalVisible(true)}
      />
    </>
  )
}

Header.defaultProps = {
  isLoggedIn: false,
}

Header.propTypes = {
  isLoggedIn: PropTypes.bool,
  siteTitle: PropTypes.string.isRequired,
  activePage: PropTypes.string.isRequired,
}

export default Header
