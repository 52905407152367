// extracted by mini-css-extract-plugin
export var notificationItem = "q_4";
export var avatar = "q_5";
export var text = "q_6";
export var unread = "q_7";
export var avatarWrapper = "q_8";
export var iconVerified = "q_9";
export var variantPage = "q_bb";
export var userName = "q_bc";
export var rotate = "q_c";
export var dotty = "q_d";