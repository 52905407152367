import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import * as style from './ConnectWalletModal.module.scss'
import ConnectWalletItem from '~components/general/ConnectWalletItem'

/**
 * ConnectWalletModal component
 */
const ConnectWalletModal = (props) => {
  const { show, onHide, className, ...rest } = props

  // Temporary behavior
  const [metamaskConnectionStatus, setMetamaskConnectionStatus] = useState()
  const loadingTimeout = useRef()

  const handleConnectMetamask = () => {
    setMetamaskConnectionStatus('loading')

    loadingTimeout.current = setTimeout(
      () => setMetamaskConnectionStatus('connected'),
      5000
    )
  }

  useEffect(() => {
    if (metamaskConnectionStatus === 'connected') onHide()
  }, [metamaskConnectionStatus])

  useEffect(() => clearTimeout(loadingTimeout.current), [])

  return (
    <Modal
      {...rest}
      show={show}
      onHide={onHide}
      scrollable
      contentClassName={className}
    >
      <Modal.Header closeButton>
        <Modal.Title>Connect your Wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body className={style.modalBody}>
        <div className={style.gridWallets}>
          <ConnectWalletItem
            name="MetaMask"
            icon="icon-metamask"
            onClick={handleConnectMetamask}
            isLoading={metamaskConnectionStatus === 'loading'}
          />
        </div>
        <p className={style.textDisclaimer}>
          We do not own your private keys and cannot access your funds without
          your confirmation.
        </p>
      </Modal.Body>
    </Modal>
  )
}

ConnectWalletModal.defaultProps = {
  className: '',
}

ConnectWalletModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default ConnectWalletModal
