import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './Avatar.module.scss'

/**
 * Avatar component
 * @see [Polkatrail Figma/Components]{@link https://www.figma.com/file/7RSB93DI7xsUJjhj7QDPs9/Polkatrail-Web-App?node-id=1559%3A8796}
 */
const Avatar = (props) => {
  const { className, variant, children, isSelected, ...rest } = props

  return (
    <button
      {...rest}
      type="button"
      className={classNames(
        style.avatar,
        [style[variant]],
        { [style.selected]: isSelected },
        className
      )}
    >
      {children}
    </button>
  )
}

Avatar.defaultProps = {
  className: '',
  variant: 'sm',
  isSelected: false,
}

Avatar.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['sm']),
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
}

export default Avatar
