/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { upperFirst } from 'lodash'

import * as style from './Social.module.scss'
import Icon from '~components/ui/Icon'
import { SOCIAL_MEDIA_LINKS } from '~utils/constants'

/**
 * Social component
 */
const Social = (props) => {
  const { className, ...rest } = props

  return (
    <div {...rest} className={classNames(style.social, className)}>
      {SOCIAL_MEDIA_LINKS.map((socialLink) => (
        <a
          key={socialLink.icon}
          href={socialLink.href}
          target="_blank"
          rel="nofollow noopener noreferrer"
          title={socialLink.title ?? upperFirst(socialLink.icon)}
        >
          <Icon name={`icon-${socialLink.icon}`} size={24} />
        </a>
      ))}
    </div>
  )
}

Social.defaultProps = {
  className: '',
}

Social.propTypes = {
  className: PropTypes.string,
}

export default Social
