import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { isNil, round } from 'lodash'
import { MOCK_EXCHANGE_COURSES } from '~utils/mocks'

const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
const updateLocale = require('dayjs/plugin/updateLocale')

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

gsap.registerPlugin(ScrollToPlugin)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a min',
    mm: '%d mins',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

/**
 * Inverse linear interpolation between values X and Y.
 * @param {number} x - value X
 * @param {number} y - value Y
 * @param {number} t - interpolated value [X, Y]
 * @returns {number} - alpha blending value [0, 1]
 */
const inverseLerp = (x, y, t) => (t - x) / (y - x)

const timestampToRelativeTime = (timestamp) => {
  const timestampDate = dayjs.unix(timestamp)
  const now = dayjs()

  // Return relative "ago" time if diff < 2 days
  if (now.diff(timestampDate, 'd') < 2) return timestampDate.fromNow()

  // Else if diff < 1 year return "16 April"
  if (now.diff(timestampDate, 'y') < 1) return timestampDate.format('D MMMM')

  // Else return "16 April 2021"
  return timestampDate.format('D MMMM YYYY')
}

const insertMiddleEllipsis = (
  text,
  containerWidth,
  charWidth = 8,
  ellipsis = '...',
  safetyMargin = 1
) => {
  const textLen = text.length
  const charsFit = Math.floor(containerWidth / charWidth)
  const charsToTrim = Math.max(0, textLen - charsFit + safetyMargin)

  if (!charsToTrim) return text

  const charsToTrimWEllipsis = charsToTrim + ellipsis.length + safetyMargin

  const part1 = text.substr(0, Math.floor(textLen / 2))
  const part2 = text.substr(Math.floor(textLen / 2))

  const part1Trimmed = part1.substr(
    0,
    part1.length - Math.floor(charsToTrimWEllipsis / 2)
  )
  const part2Trimmed = part2.substr(Math.ceil(charsToTrimWEllipsis / 2))

  return `${part1Trimmed}${ellipsis}${part2Trimmed}`
}

const handleScrollXToItem = (e, scrollElRef, columnGap, threshold = 50) => {
  const itemPosition =
    e.currentTarget.offsetLeft - scrollElRef.current.scrollLeft
  const containerWidth =
    scrollElRef.current.offsetWidth - e.currentTarget.offsetWidth

  const walletOutOfViewLeft = Math.min(0, itemPosition - threshold)
  const walletOutOfViewRight = Math.max(
    0,
    itemPosition - containerWidth + threshold
  )

  const walletScrollCompensationDelta =
    walletOutOfViewLeft || walletOutOfViewRight

  gsap.to(scrollElRef.current, {
    duration: 0.5,
    scrollTo: {
      x:
        scrollElRef.current.scrollLeft +
        walletScrollCompensationDelta +
        Math.sign(walletScrollCompensationDelta) *
          (e.currentTarget.offsetWidth / 2 + columnGap - threshold),
    },
  })
}

const objectFieldMatchesSearch = (field, str) => {
  if (!field || !str) return false
  return field?.toLowerCase()?.includes(str?.toLowerCase())
}

const convertCurrencies = (amount, currencyCodeFrom, currencyCodeTo) => {
  const exchangeCourse =
    MOCK_EXCHANGE_COURSES?.[currencyCodeFrom]?.[currencyCodeTo]

  if (isNil(exchangeCourse) || isNil(amount)) return null
  if (amount === '') return ''

  return round(amount * exchangeCourse, 8)
}

const maskNumberValue = (value) =>
  value
    .replace(',', '.') // replace `,` with `.`
    .replace(/^\./gm, '0.') // replace `.` with `0.` at the line start
    .replace(/(.*[.].*)([.])/gm, '$1') // `prevent 2 dots (e.g. `123.45.6`)
    .replace(/[^\d.]/gi, '') // allow digits and dots only

export {
  inverseLerp,
  timestampToRelativeTime,
  insertMiddleEllipsis,
  handleScrollXToItem,
  objectFieldMatchesSearch,
  convertCurrencies,
  maskNumberValue,
}
